import { Container, Flex, Heading, Stack } from '@chakra-ui/react'
import ArtificialColored from '../../../assets/images/artificial-colored.svg'
import ArtificialWhite from '../../../assets/images/artificial-white.gif'
import ArtificialGif from '../../../assets/images/servicesIcon/ai.gif'
import CrmColored from '../../../assets/images/crm-colored.svg'
import CrmWhite from '../../../assets/images/crm-white.gif'
import CrmGif from '../../../assets/images/servicesIcon/crm.gif'
import LoyaltyColored from '../../../assets/images/loyalty-colored.svg'
import LoyaltyWhite from '../../../assets/images/loyalty-white.gif'
import LoyaltyGif from '../../../assets/images/servicesIcon/loyalty.gif'
import CyberColored from '../../../assets/images/cyber-colored.png'
import CyberWhite from '../../../assets/images/cyber-white.gif'
import CyberGif from '../../../assets/images/servicesIcon/cyber.gif'
import TeamColored from '../../../assets/images/team-colored.svg'
import TeamWhite from '../../../assets/images/team-white.gif'
import TeamGif from '../../../assets/images/servicesIcon/team.gif'
import DesignColored from '../../../assets/images/design-colored.svg'
import DesignWhite from '../../../assets/images/design-white.gif'
import DesignGif from '../../../assets/images/servicesIcon/design.gif'
import LeadColored from '../../../assets/images/lead-colored.svg'
import LeadWhite from '../../../assets/images/lead-white.gif'
import LeadGif from '../../../assets/images/servicesIcon/lead.gif'
import DevopsColored from '../../../assets/images/devops-colored.png'
import DevopsWhite from '../../../assets/images/devops-white.gif'
import DevopsGif from '../../../assets/images/servicesIcon/devops.gif'
import mobileGif from '../../../assets/images/serviceAnimations/mobile.gif'
import softwareGif from '../../../assets/images/serviceAnimations/software.gif'
import cyberGif from '../../../assets/images/serviceAnimations/cyber.gif'
import blockchainGif from '../../../assets/images/serviceAnimations/blockchain.gif'
import digitalGif from '../../../assets/images/serviceAnimations/digitalM.gif'
import graphicGif from '../../../assets/images/serviceAnimations/graphics.gif'
import AIgif from '../../../assets/images/serviceAnimations/AI.gif'
import webDevGif from '../../../assets/images/serviceAnimations/webDev.gif'
import aiColored from '../../../assets/images/serviceAnimations/artificial-colored.png'
import webColored from '../../../assets/images/serviceAnimations/webdev-colored.png'
import mobileColored from '../../../assets/images/serviceAnimations/mobile-colored.png'
import digitalColored from '../../../assets/images/serviceAnimations/digital-colored.png'
import graphicsColored from '../../../assets/images/serviceAnimations/graphics-colored.png' 
import cyberColored from '../../../assets/images/serviceAnimations/cyber-colored.png' 

import { useState } from 'react'
import ServiceBox from './ServiceBox'
import aiJson from '../../../assets/images/ai.json'

export default function Services() {

    const [Services] = useState([
        {
            url: '/services',
            iconColored: aiColored,
            iconGif: AIgif,
            heading: 'AI/ML',
            items: ['Machine Learning', 'Deep Learning', 'Natural Language Processing', 'Predictive Analytics', 'Computer Vision', 'Data Analysis']
        },
        {
            url: '/services',
            iconColored: webColored,
            iconGif: webDevGif,
            heading: 'Web Development',
            items: ['Frontend Development', 'Backend Development', 'Full-Stack Development', 'Responsive Design', 'Web Performance Optimization']
        },
        {
            url: '/services',
            iconColored: mobileColored,
            iconGif: mobileGif,
            heading: 'Mobile App Development',
            items: ['iOS Development', 'Android Development', 'Cross-Platform Apps', 'UI/UX Design', 'App Store Optimization']
        },
        {
            url: '/services',
            iconColored: CyberColored,
            iconGif: softwareGif,
            heading: 'Software Development',
            items: ['Custom Software', 'Enterprise Solutions', 'Software Integration', 'API Development', 'Software Testing', 'Maintenance & Support']
        },
        {
            url: '/services',
            iconColored: digitalColored,
            iconGif: digitalGif,
            heading: 'Digital Marketing',
            items: ['SEO', 'PPC', 'Content Marketing', 'Social Media Strategy', 'Analytics & Reporting']
        },
        {
            url: '/services',
            iconColored: graphicsColored,
            iconGif: graphicGif,
            heading: 'Graphics/Animation/AR/VR',
            items: ['Graphic Design', '2D/3D Animation', 'Augmented Reality', 'Virtual Reality', 'Interactive Design', 'Motion Graphics']
        },
        {
            url: '/services',
            iconColored: cyberColored,
            iconGif: cyberGif,
            heading: 'Cyber Security',
            items: ['Threat Analysis', 'Penetration Testing', 'Vulnerability Management', 'Security Audits', 'Incident Response', 'Compliance']
        },
        {
            url: '/services',
            iconColored: DevopsColored,
            iconGif: blockchainGif,
            heading: 'Blockchain',
            items: ['Smart Contracts', 'DApps', 'Blockchain Consulting', 'Token Development', 'Blockchain Integration']
        },
    ]);

    return (
        <Stack
            // bgColor={'white.100'}
            pt={'70px'}
            pb={'90px'}
            marginTop={'0 !important'}
            bgColor={'bgGrays.100'}
        >
            <Container
                maxW={{ 'xl': 'container.xl', '2xl': '8xl' }}
            >
                <Heading
                    textAlign={'center'}
                    color={'#fff'}
                    pb={16}
                    fontSize={{ base: '26px', md: '34px' }}
                    data-aos={'fade-down'}
                    data-aos-delay={250}
                >
                    Services We Provide
                </Heading>

                <Flex
                    justifyContent={'flex-start'}
                    gap={{md:'2%'}}
                    flexWrap={'wrap'}
                >
                    {
                        Services.map((v, i) => <ServiceBox key={i} {...v} />)
                    }
                </Flex>

            </Container>
        </Stack>
    )
}
