import { Container, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import MiniBanner from '../components/Site/Banners/MiniBanner';
import ServiceSubContentOne from '../components/Site/Services/ServicesSubContent/ServiceSubContentOne';
import GirlCoder from '../assets/images/servicesIcon/girl.png';
import Nopath from '../assets/images/tech/nopath.png';
// import Techsupport from '../assets/images/tech/tech.png'
import Pic1 from '../assets/images/web/pic1.png';
import Seclock from '../assets/images/service/ss2.png';
import Pic2 from '../assets/images/web/pic2.png';
import bannerDataURL from '../utilities/bannerDataURL';
import digitalMarketing from '../assets/images/digitalMarketing.jpg'
import animation from '../assets/images/animation.jpg'
import cybersecurity from '../assets/images/cybersecurity.jpg'
import mobileapp from '../assets/images/mobileapp.jpg'
import software from '../assets/images/software.jpg'

export default function Technology() {
  const [MarketingDetailsList] = useState([
    {
      image: GirlCoder,
      mainHeading: 'Artificial Intelligence',
      content:
        'Artificial Intelligence and Machine Learning are revolutionizing how businesses operate by providing intelligent solutions that enhance decision-making, automate processes, and deliver personalized user experiences. Our team utilizes the latest AI/ML technologies to develop innovative applications that drive significant business value.',
    },
    {
      image: Pic1,
      mainHeading: 'Web Development',
      content:
        'In the digital age, a robust web presence is crucial. Our web development services encompass everything from responsive design to complex backend systems. We create dynamic, user-friendly websites that not only look great but also perform seamlessly, ensuring an optimal user experience.',
    },
    {
      image: mobileapp,
      mainHeading: 'Mobile App Development',
      content:
        'Mobile apps are essential for engaging customers on the go. Our mobile app development team specializes in creating high-performance, feature-rich apps for iOS and Android platforms. We focus on user-centric design and functionality to ensure your app stands out in the crowded app market.',
    },
    {
      image: software,
      mainHeading: 'Software Development',
      content:
        'Our software development services cater to businesses looking for custom solutions. From enterprise software to API integrations, we deliver robust, scalable, and secure software tailored to your specific needs, helping you streamline operations and enhance productivity.',
    },
    {
      image: digitalMarketing,
      mainHeading: 'Digital Marketing',
      content:
        'Digital marketing is key to reaching and engaging your target audience. Our comprehensive services include SEO, PPC, content marketing, and social media strategies. We use data-driven approaches to maximize your online presence and drive meaningful engagement with your brand.',
    },
    {
      image: animation,
      mainHeading: 'Graphics & Animation',
      content:
        'Visual content is more important than ever. Our graphics, animation, AR, and VR services bring your ideas to life, creating immersive and interactive experiences. Whether it’s 2D/3D animation or cutting-edge AR/VR applications, we deliver visually stunning solutions that captivate your audience.',
    },
    {
      image: cybersecurity,
      mainHeading: 'Cyber Security',
      content:
        'Protecting your digital assets is crucial in today’s threat landscape. Our cybersecurity services provide comprehensive protection against cyber threats. We implement robust security measures, conduct thorough risk assessments, and ensure compliance with industry standards to safeguard your business.',
    },
    {
      image: Pic2,  
      mainHeading: 'Blockchain',
      content:
        'Blockchain technology offers secure, transparent, and decentralized solutions. Our blockchain services include smart contract development, dApp creation, and blockchain consulting. We help you leverage blockchain to enhance security, streamline processes, and create innovative business models.',
    },
  ]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <>
      <Stack
        marginTop={'0 !important'}
        bgImage={bannerDataURL}
        bgSize={'cover'}
        bgRepeat={'no-repeat'}
        bgAttachment={{ base: 'inherit', md: 'fixed' }}
        height={{ base: '40vh', md: '60vh' }}
        display={'flex'}
        justifyContent={'center'}
        position={'relative'}
        zIndex={'1'}
        _after={{
          content: '""',
          position: 'absolute',
          bg: '#ffffffd9',
          w: '100%',
          h: '100%',
          zIndex: '-1',
        }}
      >
        <MiniBanner simpleHeading={'Tech'} strokeHeading={'nology'} />
      </Stack>
      <Stack bg={'#fff'}>
        <Container maxW={{ xl: 'container.lg', '2xl': '8xl' }}>
          {MarketingDetailsList.map((v, i) => (
            <ServiceSubContentOne
              {...v}
              key={i}
              direction={i % 2 !== 0 ? 'row-reverse' : 'row'}
            />
          ))}
        </Container>
      </Stack>
    </>
  );
}
